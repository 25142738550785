.statistics-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #e1e8ed;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
}

.statistics-container h2 {
    font-size: 0.9em;
    font-weight: bold;
    color: #14171a;
    margin-bottom: 20px;
    line-height: 1.2;
}

.statistics-container span {
    font-size: 0.8em;
    color: #657786;
    margin-top: 10px;
    line-height: 1.5;
}

.statistics-container .chart-container {
    margin: 20px 0;
    width: 100%;
}
