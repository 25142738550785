/* Table container styling */
.table-container {
    background-color: #f5f8fa;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

/* Table header styling */
.table-head {
    background-color: #e1e8ed;
    border-bottom: 2px solid #657786;
}

.table-header-cell {
    font-weight: bold;
    color: #14171a;
    text-align: center;
    padding: 15px;
}

/* Table row and cell styling */
.table-row {
    background-color: #ffffff;
    border-bottom: 1px solid #e6ecf0;
}

.table-row:hover {
    background-color: #f1f1f1;
}

.table-cell {
    padding: 10px;
    vertical-align: middle;
    color: #657786;
}

/* Image styling */
.table-image {
    width: 130px;
    height: 100px;
    border-radius: 8px;
    object-fit: cover;
}

/* Pagination controls styling */
.pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination-button {
    padding: 0.5rem 1.5rem;
    font-size: 0.9rem;
    border-radius: 20px;
    text-transform: uppercase;
}
