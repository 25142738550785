/* Tooltip Style */
.tooltip-box {
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffffff;
    color: #333;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 200px;
}

/* GitHub Link Tooltip Style */
.github-link {
    position: absolute;
    top: 100%;
    left: 35%;
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.96);
    color: #333;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 150px;
}

/* Button Container Style */
.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px; /* Spacing between buttons */
}

/* Button Style */
.button-container button {
    background-color: #f0f0f0;
    border: 1px solid #d1d1d1;
    border-radius: 6px;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 600;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
}

.button-container button:hover {
    background-color: #e0e0e0;
    border-color: #c0c0c0;
}

/* Link Style */
.button-container button a {
    text-decoration: none;
    color: #333;
    font-family: Arial, sans-serif;
    display: inline-block;
    width: 100%; /* Ensures link takes full button width */
    text-align: center;
}

.button-container button a:hover {
    text-decoration: underline;
}
