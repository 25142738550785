/* General layout */
.twitter-container {
    width: auto;
    padding: 20px;
    background-color: #f9fbfc;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.twitter-section-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #14171a;
    margin-bottom: 20px;
}

/* Twitter-like feed */
.twitter-feed {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

/* Cards */
.twitter-card {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #e6ecf0;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.15s ease, box-shadow 0.15s ease; /* Slight movement */
    max-width: 100%;
}

.twitter-card:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15); /* Softer shadow on hover */
}

.twitter-card-date {
    font-size: 0.9rem;
    color: #657786;
    margin-bottom: 10px;
}

/* Actions */
.twitter-card-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

.twitter-card-actions button {
    font-size: 0.85rem; /* Smaller buttons for compact design */
}

/* No data */
.no-data {
    text-align: center;
    font-size: 1rem;
    color: #b0b7bc;
    margin-top: 20px;
}
