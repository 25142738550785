.user-info-card {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 16px;
    max-width: 400px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    color: #333333;
}

.user-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.user-name {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333333;
    margin: 0;
}

.user-role {
    font-size: 0.9rem;
    font-weight: 600;
    color: #ffffff;
    border-radius: 8px;
    padding: 4px 8px;
    text-transform: uppercase;
}

.role-description {
    font-size: 0.9rem;
    color: #666666;
    margin-bottom: 16px;
    text-align: left;
    white-space: pre-line;
}

.user-info-details {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #f0f0f0;
}

.info-row:last-child {
    border-bottom: none;
}

.info-label {
    font-size: 0.9rem;
    font-weight: bold;
    color: #555555;
}

.info-value {
    font-size: 0.9rem;
    color: #333333;
    text-align: right;
}

@media (max-width: 480px) {
    .user-info-card {
        padding: 12px;
    }

    .user-name {
        font-size: 1.2rem;
    }

    .info-row {
        flex-direction: column;
        align-items: flex-start;
    }

    .info-value {
        text-align: left;
    }
}
