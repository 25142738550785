.fade-in-text {
    opacity: 0;
    animation: fadeIn 0.05s ease-in forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
