.dynamic-size-modal {
    width: auto;
    max-width: 60%;
    border-radius: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.dynamic-size-modal .modal-body {
    max-height: 70vh;
    overflow-y: auto;
    padding: 1.5rem;
    background: #fff;
}

.dynamic-size-modal .modal-header,
.dynamic-size-modal .modal-footer {
    background: #f9f9f9;
    padding: 1rem 1.5rem;
    border: none;
}
