.tournament-ui {
    display: flex;
    justify-content: space-between;
    height: 100vh; /* Full viewport height */
}

.left-panel {
    align-items: center;
}

.right-panel {
    flex: 1; /* Equal space for both panels */
    padding: 10px;
    border-right: 1px solid #ccc;
    display: flex;
    flex-direction: column;
}

.right-panel {
    border-right: none; /* Remove border for the right panel */
}
