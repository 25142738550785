.round-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
}

.pair-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.single-item-container {
    text-align: center;
}

.connector {
    height: 2px;
    width: 20px;
    background-color: #ccc;
    margin: 0 5px 25px;
}

.single-item-container:hover,
.pair-container:hover {
    background-color: #f0f0f0;
    border-radius: 8px;
    transition: background-color 0.3s ease;
}


td p {
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
}

@media (max-width: 768px) {
    .round-container {
        flex-direction: column;
    }

    .connector {
        width: 2px;
        height: 20px;
        margin: 5px 0;
    }
}

