.user-page-layout {
    display: flex;
    min-height: 90vh;
    background-color: #f8f9fa;
}

.content-area {
    flex-grow: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    margin-left: 180px;
}

.top-section {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 24px;
}

.score-section,
.info-section {
    flex: 1;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.bottom-section {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
}

.bottom-section > * {
    flex: 1 1 calc(25% - 16px);
    max-width: calc(100% - 16px);
    background-color: #ffffff;
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.bottom-section > *:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
    .top-section {
        flex-direction: column;
    }

    .bottom-section > * {
        flex: 1 1 100%;
        max-width: 100%;
    }
}
