/* Horizontal alignment container for preference cards */
.preference-card-container-horizontal {
    display: flex;
    flex-wrap: nowrap; /* Prevent wrapping */
    gap: 16px;
    justify-content: space-between;
    align-items: flex-start;
    overflow-x: auto; /* Add horizontal scroll if necessary */
    padding: 16px;
    background-color: #f8f9fa;
    border-radius: 12px;
}

/* Individual card styling */
.preference-card {
    flex: 0 0 calc(25% - 16px); /* Ensure all cards fit in 4-column layout */
    max-width: 240px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
    padding: 16px;
}

.preference-card:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

/* Title styling */
.preference-title {
    font-size: 1.0rem;
    font-weight: 600;
    color: #333333;
    margin-bottom: 12px;
}

/* Image styling */
.preference-image {
    width: 100%;
    height: 140px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 12px;
}

/* Food name styling */
.preference-name {
    font-size: 1rem;
    font-weight: 500;
    color: #555555;
    margin-bottom: 8px;
}

/* Value styling */
.preference-value {
    font-size: 0.9rem;
    font-weight: 400;
    color: #888888;
    margin-top: 4px;
}

/* Scroll support for horizontal layout */
.preference-card-container-horizontal::-webkit-scrollbar {
    height: 8px;
}

.preference-card-container-horizontal::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

.preference-card-container-horizontal::-webkit-scrollbar-track {
    background-color: #f8f9fa;
}

/* Responsive Design */
@media (max-width: 768px) {
    .preference-card {
        flex: 0 0 calc(50% - 16px); /* Fit 2 cards per row on smaller screens */
    }
}

@media (max-width: 480px) {
    .preference-card {
        flex: 0 0 calc(100% - 16px); /* Fit 1 card per row on very small screens */
    }
}
