.chat-info {
    height: 90vh;
    padding: 20px;
    overflow: hidden;
    max-width: 100vw;
    box-sizing: border-box;
    margin-top: 56px;
}

.user-list-items {
    gap: 10px;
    white-space: nowrap;
    overflow-x: auto;
}

.chat-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
}

.messages-container {
    height: 400px;
    overflow-y: auto;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    flex-direction: column; /* Stack messages vertically */
    gap: 10px;
}

.message {
    max-width: 70%; /* Limit the message width */
    padding: 8px;
    border-radius: 4px;
    background-color: #f1f1f1;
    display: inline-block;
    align-self: flex-start; /* Default alignment: left */
}

.my-message {
    background-color: #d1e7dd;
    align-self: flex-end; /* Align my messages to the right */
}

.host-message {
    max-width: 100%; /* Stretch across the container */
    text-align: center; /* Center-align the text */
    font-size: 0.85rem; /* Smaller text */
    font-family: 'Courier New', Courier, monospace; /* Different font */
    color: #555; /* Subtle color */
    background-color: transparent; /* Optional: no background */
    padding: 5px;
    border-radius: 0; /* Optional: no border-radius */
}

.input-container {
    display: flex;
    gap: 10px;
}

input[type="text"] {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}