/* Sidebar container */
.twitter-sidenav {
    background-color: #ffffff; /* White background for clean design */
    border-right: 1px solid #e6ecf0; /* Subtle border to define the sidebar */
    position: fixed;
    top: 56px;
    left: 0;
    width: 160px; /* Wider for better readability */
    height: 100vh;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: start;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Soft shadow for depth */
}

/* Sidebar list */
.sidenav-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

/* Sidebar item */
.sidenav-item {
    margin-bottom: 20px;
}

/* Sidebar links */
.sidenav-link {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    font-size: 1rem;
    font-weight: 600;
    color: #14171a; /* Dark text color for readability */
    text-decoration: none;
    border-radius: 8px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.sidenav-link:hover {
    background-color: #e8f5fd; /* Light blue hover effect */
    color: #1da1f2; /* Twitter blue text */
}

/* Sidebar icons */
.sidenav-icon {
    font-size: 1.5rem;
    margin-right: 10px;
    color: #657786; /* Gray color for icons */
}

/* Sidebar text */
.sidenav-text {
    flex-grow: 1;
}
