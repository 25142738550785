.tournament-view-box {
    margin-top: 50px;
    margin-left: 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #ffffff;
    border: 1px solid #e1e8ed;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
    height: 90%;
    width: 80%;
}

.title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px;
}

.image-container {
    width: 80%;
    height: 55%;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 16px;
}

.item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.item-name {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 8px 0;
    color: #333;
    text-align: center;
}

.stats-table {
    width: 20%;
    text-align: left;
    border-collapse: collapse;
    font-size: 14px;
    color: #555;
    margin-top: auto;
}

.stat-label {
    padding: 8px 0;
    font-weight: 500;
}

.stat-value {
    padding: 8px 0;
    text-align: right;
}
