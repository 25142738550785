.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.thumbnail-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 150px;
    gap: 10px;
    justify-content: center;
    align-items: center;
    max-width: 320px;
}

.thumbnail-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
    gap: 10px; /* Space between grid cells */
    justify-content: center; /* Center the grid in its container */
    max-width: 320px; /* Restrict the grid's width to maintain consistency */
}

.thumbnail-grid > div {
    width: 150px; /* Fixed square width */
    height: 150px; /* Fixed square height */
    display: flex; /* Flexbox for content alignment */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    background-color: #f5f5f5; /* Neutral background color */
    border: 1px solid #ddd; /* Subtle border for consistent appearance */
    border-radius: 5px; /* Optional: Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Adds depth */
    overflow: hidden; /* Ensures no content spills outside the cell */
}

.thumbnail-image {
    width: 100%; /* Scale to fill the container width */
    height: 100%; /* Scale to fill the container height */
    object-fit: cover; /* Maintain aspect ratio while cropping to fit */
    border-radius: 5px; /* Match the cell’s rounded corners */
}

.main-content {
    margin-top: 56px;
}

.main-content-sidebar {
    margin-left: 150px;
    padding: 20px;
}
